<template>
    <section>
        <div class="content-wrapper">
            <div class="row">
                <h1>Mes réservations</h1>
                <div class="col-md-12">
                    <div v-if="isload" class="jumping-dots-loader">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <b-table 
                    :items="items" 
                    id="table-list" 
                    responsive :per-page="perPage" 
                    :current-page="currentPage" 
                    :fields="fields" 
                    :sort-by.sync="sortBy" 
                    :sort-desc.sync="sortDesc"
                    :filter="search"
                    selectable
                    @row-clicked="details"
                >
                    <!-- Nom du client -->
                    <template #cell(client)="row">
                        <!-- <span class="pointer" @click="ordersDetails(row.item.ulid)" v-if="row.item.deliveryinfos.name">{{row.item.deliveryinfos.name}}</span> -->
                        <span v-if="row.item.on_behalf_of">{{row.item.on_behalf_of.name}}</span>
                        <span v-else>indéfini</span>
                    </template>

                    <!-- les prestations -->
                    <template #cell(type)="row">
                        <span v-if="row.item.appointments">
                            {{ row.item.appointments[0].service.name }}
                            <span v-if = "row.item.appointments.length>1">
                                ...
                            </span>
                        </span>
                        <span v-else>Vide</span>
                    </template>
                    
                    <!-- date de réservation ou date d'achat -->
                    <template #cell(date)="row">
                        <span>{{formatDate2(row.item.appointments[0].start_hour)}}</span>
                    </template>

                    <!-- état -->
                    <template #cell(status)="row">
                        <span v-if="row.item.status !=='cancelled'">
                            <span class="badge badge-success" v-if="new Date(row.item.appointments[0].start_hour)< Date.now()">
                                Terminée
                            </span>
                            <span class="badge badge-primary" v-else>à venir</span>
                        </span>
                        <span class="badge badge-danger" v-else>
                            Annulée
                        </span>
                    </template>

                    <!-- <template #cell(#)="row">
                        <a @click="removeReservation(row)" class="btn btn-danger btn-sm"><i class="fa fa-trash" ></i></a>
                    </template> -->
                </b-table>
                <h3 v-if="items.length<1">Vous n'avez pas de réservation</h3>
                <b-pagination
                v-else
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="table-list"
                  align="right">
                </b-pagination>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import { dateMixin } from './mixins/date'
export default {
    name:"myReservations",
    mixins:[dateMixin],
    data(){
        return{
            perPage: 10,
            isload:true,
            sortBy: 'name',
            currentPage: 1,
            sortDesc: false,
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true,
            search:'',
            fields: [
            { key: 'client', label:'Bénéficiaire', sortable: true },
            { key: 'type', label:"Prestations", sortable: true },
            { key: 'date', sortable: true },
            { key: 'status', sortable: true },
            // { key: '#' }
            ],
            items: [
            ]
        }
    },
    computed:{
        rows() {
        return this.items.length
      }
    },
    methods: {
        details(record){
            this.reservationDetails(record.ulid)
        },
        reservationDetails(ulid) {
            this.$router.push({
            name:"reservationDetails",
            params: {
                ulid: ulid
            }
            })
        },
        getMyReservations(){
            axios.get('myreservations?orderby_created_at')
            .then(resMyreservations => {
                this.items = resMyreservations.data.result
                this.isload = false
            })
            .catch(errMyReservations => {
                errMyReservations
            })
        },
        removeReservation(ulid){
            axios.delete(`myreservations/${ulid}`)
            .then(resRemove =>{
                console.log({resRemove});
            })
            .catch(errRemove =>{
                console.log({errRemove});
            })
        }

    },
    mounted() {
        this.getMyReservations()
    },
    beforeCreate() {
        // mettre X-Auth-token sur toutes les requêtes de la page
        axios.defaults.headers.common['X-AUTH-TOKEN'] = localStorage.getItem('token');
    }
}
</script>